import { InjectionToken } from '@angular/core';

import Settings from '../builder/instructions/settings/settings';

import { AlertService } from '../app/components/alert/alert.service';

import { AuthenticationCallbackRepositoryInterface } from './authentication/interfaces/repositories/authentication-callback.repository.interface';
import { AuthenticationRepositoryInterface } from './authentication/interfaces/repositories/authentication.repository.interface';
import { AuthenticationCallbackServiceInterface } from './authentication/interfaces/services/authentication-callback.service.interface';
import { GetAuthenticationServiceInterface } from './authentication/interfaces/services/get-authentication.service.interface';
import { SetAuthenticationServiceInterface } from './authentication/interfaces/services/set-authentication.service.interface';
import { DocumentRepositoryInterface } from './document/interfaces/repositories/document.repository.interface';
import { GetRemoteDocumentServiceInterface } from './document/interfaces/services/get-remote-document.service.interface';
import { UploadDocumentServiceInterface } from './document/interfaces/services/upload-document.service.interface';
import { GetSignatureAdapterInterface } from './signatures/interfaces/adapters/get-signature.adapter.interface';
import { CertificatesInterface } from './signatures/interfaces/certificates.interface';
import { CreateSignatureServiceInterface } from './signatures/interfaces/create-signature.service.interface';
import { SignatureRepositoryInterface } from './signatures/interfaces/repositories/signature.repository.interface';
import { SignStreamServiceFactoryInterface } from './signatures/interfaces/factories/sign-stream.service.factory.interface';
import { SignerInterface } from './signatures/interfaces/signer.interface';
import { GetCurrentUserServiceInterface } from './user/interfaces/services/get-current-user.service.interface';
import { GetDocumentsSignaturesServiceInterface } from './signatures/interfaces/get-documents-signatures.service.interface';
import { ParallelSignStreamQueuesServiceInterface } from './signatures/interfaces/parallel-sign-stream-queues.service.interface';
import { SignAndUploadDocumentServiceInterface } from './document/interfaces/services/sign-and-upload-document.service.interface';
import { DocumentStorageSignQueueServiceInterface } from './signatures/interfaces/document-storage.sign-queue.service.interface';
import { GetInternalCertificateServiceInterface } from './signatures/interfaces/get-internal-certificate.service.interface';
import { InternalCertificateRepositoryInterface } from './signatures/interfaces/repositories/internal-certificate.repository.interface';
import { CreateInternalCertificateServiceInterface } from './signatures/interfaces/create-internal-certificate.service.interface';
import { VerifyPasswordServiceInterface } from './authentication/interfaces/services/verify-password.service.interface';
import { BatchDownloadDocumentsServiceInterface } from './document/interfaces/services/batch-download-documents.service.interface';
import { ApiActionAlertManagerService } from './api-action/services/api-action-alert-manager.service';
import { ActorApiServiceInterface } from './actor-api/interfaces/services/actor-api.interface';

const Types = {
  Settings: new InjectionToken<Settings>('Settings'),
  Signer: new InjectionToken<SignerInterface>('Signer'),
  SignStreamServiceFactory: new InjectionToken<SignStreamServiceFactoryInterface>('SignStreamServiceFactory'),
  ParallelSignStreamQueuesService: new InjectionToken<ParallelSignStreamQueuesServiceInterface>(
    'ParallelSignStreamQueuesService'
  ),
  DocumentStorageSignQueueService: new InjectionToken<DocumentStorageSignQueueServiceInterface>(
    'DocumentStorageSignQueueService'
  ),
  SignAndUploadDocumentService: new InjectionToken<SignAndUploadDocumentServiceInterface>(
    'SignAndUploadDocumentService'
  ),
  Certificates: new InjectionToken<CertificatesInterface>('Certificates'),
  DocumentRepository: new InjectionToken<DocumentRepositoryInterface>('DocumentRepository'),
  SignatureRepository: new InjectionToken<SignatureRepositoryInterface>('SignatureRepository'),
  AuthenticationRepository: new InjectionToken<AuthenticationRepositoryInterface>('AuthenticationRepository'),
  AuthenticationCallbackRepository: new InjectionToken<AuthenticationCallbackRepositoryInterface>(
    'AuthenticationCallbackRepository'
  ),
  InternalCertificateRepository: new InjectionToken<InternalCertificateRepositoryInterface>(
    'InternalCertificateRepository'
  ),
  GetRemoteDocumentService: new InjectionToken<GetRemoteDocumentServiceInterface>('GetRemoteDocumentService'),
  UploadDocumentService: new InjectionToken<UploadDocumentServiceInterface>('UploadDocumentService'),
  GetAuthenticationService: new InjectionToken<GetAuthenticationServiceInterface>('GetAuthenticationService'),
  SetAuthenticationService: new InjectionToken<SetAuthenticationServiceInterface>('SetAuthenticationService'),
  AuthenticationCallbackService: new InjectionToken<AuthenticationCallbackServiceInterface>(
    'AuthenticationCallbackService'
  ),
  VerifyPasswordService: new InjectionToken<VerifyPasswordServiceInterface>('VerifyPasswordService'),
  GetCurrentUserService: new InjectionToken<GetCurrentUserServiceInterface>('GetCurrentUserService'),
  GetSignatureAdapter: new InjectionToken<GetSignatureAdapterInterface>('GetSignatureAdapter'),
  CreateSignature: new InjectionToken<CreateSignatureServiceInterface>('CreateSignature'),
  GetDocumentsSignaturesService: new InjectionToken<GetDocumentsSignaturesServiceInterface>(
    'GetDocumentsSignaturesService'
  ),

  GetCertificateService: new InjectionToken<GetInternalCertificateServiceInterface>('GetCertificateService'),
  CreateCertificateService: new InjectionToken<CreateInternalCertificateServiceInterface>('CreateCertificateService'),

  BatchDownloadDocumentsService: new InjectionToken<BatchDownloadDocumentsServiceInterface>(
    'BatchDownloadDocumentsService'
  ),
  AlertComponentService: new InjectionToken<AlertService>('AlertComponentService'),
  ApiActionAlertManagerService: new InjectionToken<ApiActionAlertManagerService>('ApiActionAlertManagerService'),
  ActorApiService: new InjectionToken<ActorApiServiceInterface>('ActorApiService'),
};

export { Types };
